<template>
  <div class="container">
    <div class="row py-5 auth-row">
      <div class="col-12 col-md-5">
        <img
          class="img-fluid"
          src="@/assets/img/cedaci-img.jpg"
          alt="CEDaCI Main image"
        />
      </div>
      <div class="col-12 col-md-7 text-center">
        <h1 class="title-w2 margin-w3">Reset your password</h1>
        <form @submit.prevent="resetPassword">
          <div class="form-item input-group w-75 mb-4">
            <label class="form-label">Password</label>
            <span class="form-error">{{ formError.password }}</span>
            <input
              :type="showPassword ? 'text' : 'password'"
              class="form-control"
              placeholder="Your new password"
              v-model="form.password"
              required
            />
            <span class="input-group-text cursor-pointer"
              ><i
                :class="
                  showPassword ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill'
                "
                @click="showPassword = !showPassword"
              ></i
            ></span>
          </div>

          <div class="form-item input-group w-75 mb-4">
            <label class="form-label">Confirm password</label>
            <span class="form-error">{{ formError.passwordConfirm }}</span>
            <input
              :type="showConfirmPassword ? 'text' : 'password'"
              class="form-control"
              placeholder="Please confirm your new password"
              v-model="form.passwordConfirm"
              required
            />
            <span class="input-group-text cursor-pointer"
              ><i
                :class="
                  showConfirmPassword
                    ? 'bi bi-eye-slash-fill'
                    : 'bi bi-eye-fill'
                "
                @click="showConfirmPassword = !showConfirmPassword"
              ></i
            ></span>
          </div>
          <button class="btn mt-4 mb-4">Reset Password</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPasswordValidation,
  getPasswordConfirmation,
} from '@/utils/helpers/getValidation';
export default {
  name: 'ResetPassword',
  data() {
    return {
      token: '',
      form: {
        password: '',
        passwordConfirm: '',
      },
      formError: {
        password: '',
        passwordConfirm: '',
      },
      isValid: false,
      showConfirmPassword: false,
      showPassword: false,
    };
  },
  created() {
    this.token = this.$route.params.token;
  },
  methods: {
    validateForm() {
      const passwordValidation = getPasswordValidation(this.form.password);
      const passwordConfirmation = getPasswordConfirmation(
        this.form.password,
        this.form.passwordConfirm
      );

      this.formError = {
        password: passwordValidation,
        passwordConfirm: passwordConfirmation,
      };

      if (passwordValidation === '' && passwordConfirmation === '') {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    },
    resetPassword() {
      this.validateForm();
      if (!this.isValid) {
        return;
      }

      this.$store
        .dispatch('resetPassword', {
          token: this.token,
          password: this.form.password,
        })
        .then(() => {
          this.$router.push({ name: 'Login' });
        });
    },
  },
};
</script>
